import "core-js/modules/es.array.push.js";
import footerBar from "./footer.vue";
import { register, sendCode } from "../../api/application/index";
import { reactive, ref, onMounted } from "vue";
import { Toast, Popup } from "vant";
import '../../utils/jquery-3.3.1.min.js';
import '../../utils/jquery.cookie.js';
import oha_api from '../../utils/oha_api';
export default {
  data() {
    return {
      popupshow: false,
      phone: '',
      code: '',
      time: 60,
      oldlist: ['18岁以下', '19-25岁', '26-35岁', '36-45岁', '45岁以上'],
      codeyes: false,
      girl: null,
      checked: 1,
      agename: '',
      yschecked: false,
      password: ''
    };
  },
  components: {
    footerBar
  },
  methods: {
    goback() {
      if (!this.yschecked) {
        $Toast('请先阅读并同意用户隐私政策');
        return;
      }
      if (this.phone.length != 11) {
        $Toast('请输入正确手机号');
        return;
      }
      if (this.code == '') {
        $Toast('验证码不能为空');
        return;
      }
      if (this.agename == '') {
        $Toast('请先选择年龄段');
        return;
      }
      if (this.password == '') {
        $Toast('密码不能为空');
        return;
      }
      console.log(this.checked);
      register({
        phone: this.phone,
        //手机号
        password: this.password,
        //密码
        age: this.agename,
        //年龄段
        gender: this.checked == 1 ? 0 : 1,
        //性别1男 0女 其他暂不公开
        code: this.code //验证码
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          localStorage.setItem('dzmmtoken', res.data);
          oha_api.cookieWrite('userinfo', res.data);
          sessionStorage.setItem('dzmmtoken', res.data);
          $Toast('注册成功');
          setTimeout(() => {
            this.$router.replace({
              path: '/api/index/default.html'
            });
          }, 1200);
        }
      });
    },
    timedel() {
      let timedemo = setInterval(() => {
        this.time--;
        if (this.time == 0) {
          this.codeyes = false;
          clearInterval(timedemo);
        }
      }, 1000);
    },
    //获取验证码
    getcode() {
      if (this.phone.length != 11) {
        $Toast('请输入正确手机号');
        return;
      }
      sendCode({
        phone: this.phone
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.codeyes = true;
          this.timedel();
          $Toast('验证码已发送');
        }
      });
    },
    //点击隐私政策
    goyszc() {
      this.$router.push({
        path: '/home/yszc'
      });
    },
    showPopup() {
      this.show = true;
    },
    onConfirm(value, index) {
      this.agename = value;
      this.popupshow = false;
    },
    onCancel() {
      this.popupshow = false;
    }
  }
};